import { USER_GIFT } from '../../data/user/constant';

export const MODULE_NAME = 'GIFT_USER_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  // URL: 'user/user-gift-list/', раскоментировать
  URL: 'user/user-gift-list/hiiden',
};

export interface GIFT_DETAIL {
  img: string;
  title: string;
  price: string;
  id: USER_GIFT;
}

export interface GIFT_DATA_ENUM {
  [USER_GIFT.MERCH]: GIFT_DETAIL;
  [USER_GIFT.AIRPODS]: GIFT_DETAIL;
  [USER_GIFT.WALLET]: GIFT_DETAIL;
  [USER_GIFT.APPLE_WATCH]: GIFT_DETAIL;
  [USER_GIFT.IPHONE_MACBOOK]: GIFT_DETAIL;
}

export interface GIFT_ITEM_DATA_RAW_INTER {
  list: USER_GIFT[];
}

export interface GIFT_ITEM_DATA_INTER
  extends Omit<GIFT_ITEM_DATA_RAW_INTER, 'list'> {
  list: GIFT_DETAIL[];
  isEmpty: boolean;
}

export interface DATA_INTER extends GIFT_ITEM_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface ACTION_RESPONSE_INTER extends GIFT_ITEM_DATA_RAW_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const GIFT_DATA: GIFT_DATA_ENUM = {
  [USER_GIFT.MERCH]: {
    img: 'USER.GIFT.LIST.0.IMG',
    title: 'USER.GIFT.LIST.0.TITLE',
    price: 'USER.GIFT.LIST.0.PRICE',
    id: USER_GIFT.MERCH,
  },
  [USER_GIFT.AIRPODS]: {
    img: 'USER.GIFT.LIST.1.IMG',
    title: 'USER.GIFT.LIST.1.TITLE',
    price: 'USER.GIFT.LIST.1.PRICE',
    id: USER_GIFT.AIRPODS,
  },
  [USER_GIFT.WALLET]: {
    img: 'USER.GIFT.LIST.2.IMG',
    title: 'USER.GIFT.LIST.2.TITLE',
    price: 'USER.GIFT.LIST.2.PRICE',
    id: USER_GIFT.WALLET,
  },
  [USER_GIFT.APPLE_WATCH]: {
    img: 'USER.GIFT.LIST.3.IMG',
    title: 'USER.GIFT.LIST.3.TITLE',
    price: 'USER.GIFT.LIST.3.PRICE',
    id: USER_GIFT.APPLE_WATCH,
  },
  [USER_GIFT.IPHONE_MACBOOK]: {
    img: 'USER.GIFT.LIST.4.IMG',
    title: 'USER.GIFT.LIST.4.TITLE',
    price: 'USER.GIFT.LIST.4.PRICE',
    id: USER_GIFT.IPHONE_MACBOOK,
  },
};
